
   
import React from "react"
import Page from 'components/Page'
import Layout from "components/Layout"
import useStoryblok from 'utils/storyblok'
import Button from "utils/Button"
import Seo from "utils/seo"

export default function Page404({ location }) {
  const story = useStoryblok(null, location)

  let content = (
    <div className="bg-verylightgrey min-h-screen text-darkgrey flex flex-col justify-center items-center">
      <div className="max-w-3xl mx-auto px-6 flex flex-col">
        <h1 className="lg:text-[100px] md:text-8xl text-6.2xl mb-4 leading-none">404.</h1>
        <p className="max-w-[533px] md:text-xl text-lg !leading-tight">
          The page you're looking for doesn't exist or has been moved.<br/>
          Go back to our homepage and see if you can find what you're looking for.
        </p>
        <Button to="/" className="self-end mt-8">Back to homepage</Button>
      </div>
    </div>
  )
  if(story && story.content) content = (<Page blok={story.content} />)

  return (
    <>
      <Seo pageName={"404"} pageMeta={{description: 'Page not found'}}/>
      <Layout location={location}>
        { content }
      </Layout>
    </>
  )
}